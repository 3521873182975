<template>
  <div>
    <template v-if="(value as QuestionBlock)?.isBlock">
      <UiButtonLink
        class="flex w-full items-start justify-between !pb-1.5 !pt-5 text-left"
        @click="open = !open"
      >
        <UiTitle severity="h5" tag="h3" class="!font-bold" v-html="value.title" />
        <UiIcon
          name="chevron-down"
          class="mt-1.5 size-4 min-w-4 text-icon-tertiary transition-transform"
          :class="{ 'rotate-180': open }"
        />
      </UiButtonLink>
      <TransitionExpand>
        <ul v-if="open">
          <li v-for="question in (value as QuestionBlock).questions" :key="question.title">
            <FAQQuestion :value="question as Question" class="mt-1.5" :open-all="openAll" />
          </li>
        </ul>
      </TransitionExpand>
    </template>
    <template v-else>
      <UiButtonLink
        size="small"
        class="flex w-full items-start justify-between !pb-1.5 !pt-5 text-left"
        @click="open = !open"
      >
        <h3 class="text-sm font-medium" v-html="value.title" />
        <UiIcon
          name="chevron-down"
          class="mt-1 size-4 min-w-4 text-icon-tertiary transition-transform"
          :class="{ 'rotate-180': open }"
        />
      </UiButtonLink>
      <TransitionExpand>
        <div v-if="open" class="page-content mt-1.5" v-html="(value as Question).text" />
      </TransitionExpand>
    </template>
  </div>
</template>

<script lang="ts" setup>
import { TransitionExpand } from '@morev/vue-transitions'
import { ref } from 'vue'
import { UiButtonLink, UiIcon, UiTitle } from '@/6_shared/ui'
import type { Question, QuestionBlock } from '../model'

type PropType = {
  value: QuestionBlock | Question
  openAll?: boolean
}

const props = withDefaults(defineProps<PropType>(), {
  openAll: false
})
const open = ref(props.openAll)
</script>
