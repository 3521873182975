<template>
  <UiContainer tag="section">
    <ul class="flex flex-wrap gap-3">
      <li v-for="button in sections" :key="'button' + button.id">
        <UiButton
          severity="secondary"
          :active="activeSection === button.id"
          :class="{ '!bg-primary !text-text-main': activeSection === button.id }"
          @click="
            () => {
              emit('update:activeSection', button.id)
              emit('update:showAll', false)
            }
          "
        >
          {{ button.title }}
        </UiButton>
      </li>
      <li v-if="!showAllSections">
        <UiButton
          class="flex items-center"
          severity="secondary"
          @click="emit('update:showAllSections', true)"
        >
          другие вопросы
          <UiIcon name="x-mark" class="ml-1 size-4 rotate-45" />
        </UiButton>
      </li>
      <li v-else>
        <UiButton
          severity="secondary"
          class="flex items-center"
          @click="
            () => {
              emit('update:showAll', true)
              emit('update:activeSection', undefined)
            }
          "
        >
          хочу посмотреть все ответы на вопросы
        </UiButton>
      </li>
    </ul>
  </UiContainer>
</template>

<script setup lang="ts">
import type { FAQ } from '@/5_entities/FAQ'
import { UiButton, UiContainer, UiIcon } from '@/6_shared/ui'

type PropType = {
  sections: Pick<FAQ, 'id' | 'title'>[]
  activeSection?: number
  showAllSections: boolean
  showAll: boolean
}
type EmitType = {
  (e: 'update:showAllSections', value: boolean): void
  (e: 'update:activeSection', value?: number): void
  (e: 'update:showAll', value: boolean): void
}

defineProps<PropType>()
const emit = defineEmits<EmitType>()
</script>
