<template>
  <main class="pt-5 md:pt-0">
    <UiFixedHeader> Ответы на частые вопросы </UiFixedHeader>
    <UiContainer class="mb-4 flex max-w-[670px] items-center">
      <GoBack class="-ml-4 mr-1.5 bg-transparent" />
      <UiTitle severity="h1"> Ответы на частые вопросы </UiTitle>
    </UiContainer>
    <FilterFAQSections
      v-model:show-all-sections="showAllSections"
      v-model:active-section="activeSection"
      v-model:show-all="showAll"
      :sections="sections"
      class="mb-7 max-w-[670px]"
    />
    <FAQQuestions
      v-if="!showAll && activeSectionQuestions"
      :value="activeSectionQuestions"
      class="max-w-[670px]"
    />

    <template v-if="showAll">
      <FAQQuestions v-for="question in questions" :key="question.id" :value="question" open-all />
    </template>
  </main>
</template>

<script setup lang="ts">
import { computed, reactive, ref } from 'vue'
import { FilterFAQSections } from '@/4_features/FAQ'
import { GoBack } from '@/4_features/Navigation'
import { type FAQ, FAQQuestions } from '@/5_entities/FAQ'
import { ExternalLink } from '@/6_shared/config'
import { useBreakpoint } from '@/6_shared/lib'
import { UiContainer, UiFixedHeader, UiTitle } from '@/6_shared/ui'

const {
  lg: [isLgAndUp]
} = useBreakpoint()

const questions = reactive<FAQ[]>([
  {
    id: 0,
    title: 'как вернуть билет?',
    description: {
      title: 'как вернуть билет?',
      text: `Обращаем ваше внимание, автоматический возврат билетов возможен не&nbsp;во&nbsp;всех случаях. Это происходит из-за особых условий по&nbsp;возврату билетов, которые выдвигают организаторы мероприятий. Вы&nbsp;можете оформить возврат, следуя инструкции, на&nbsp;странице <a href="${ExternalLink.refund}">возврат билетов.</a>`
    },
    questions: [
      {
        title: 'Возврат билетов',
        active: false,
        isBlock: true,
        questions: [
          {
            title: 'Можно вернуть только один билет из&nbsp;заказа, если покупалось несколько?',
            text: 'Да, частичный возврат возможен. При осуществлении операции возврата, вам нужно будет выбрать определенные билеты, которые вы&nbsp;хотите вернуть, отметив их&nbsp;галочкой.',
            active: false
          },
          {
            title: 'Если у меня изменились данные банковской карты?',
            text: 'Если у&nbsp;вас изменилась карта, а&nbsp;расчётный счёт остался прежним, то&nbsp;денежные средства будут зачислены на&nbsp;тот&nbsp;же счёт.<br/><br/>Если с&nbsp;заменой карты изменился номер расчётного счета, необходимо обратиться к&nbsp;организатору мероприятия и&nbsp;предоставить реквизиты нового счёта.',
            active: false
          },
          {
            title: 'Как быстро денежные средства вернутся мне на карту?',
            text: 'Как правило, возврат средств занимает от&nbsp;3&nbsp;до&nbsp;5&nbsp;дней. В&nbsp;редких случаях, возврат может занимать до&nbsp;30&nbsp;дней (зависит от&nbsp;Банка, выпустившего вашу карту).',
            active: false
          },
          {
            title: 'Что значит: заказ не найден?',
            text: `При процедуре возврата заказ может быть не&nbsp;найден в&nbsp;следующих случаях:<br>
<ul>
<li> Неверно введен номер заказа</li>
<li>Заказ оформлен в другой билетной системе</li>
<li>Билет является пригласительным/выигрышным. Билет не&nbsp;может быть возвращен, так как он&nbsp;не&nbsp;был оплачен.</li>
<li>Возврат по&nbsp;данному заказу производит организатор, так как денежные средства при покупке билетов были направлены именно организатору.</li>
<li>Если после проверки всех вышеуказанных пунктов проблема не&nbsp;устранена, напишите нам в&nbsp;форму обратной связи. Наши специалисты оперативно помогут решить ваш вопрос.</li>
</ul>`,
            active: false
          },
          {
            title: 'Почему система не производит возврат, а рекомендует оформить его позже?',
            text: `Это означает, что система не&nbsp;может произвести возврат прямо сейчас. Рекомендуем оформить заявку на&nbsp;возврат позже, так как к&nbsp;с&nbsp;организатором идет согласование порядка и&nbsp;сроков возврата билетов. Вы&nbsp;можете оформить заявку позже или написать нам в&nbsp;<a href="${ExternalLink.feedback}">форму обратной связи</a>. Наши специалисты оперативно помогут решить ваш вопрос.`,
            active: false
          },
          {
            title: 'Что означает сообщение "возврат завершен" при процедуре возврата?',
            text: 'Это означает, что возврат по&nbsp;вашему мероприятию более не&nbsp;производится, так как сроки проведения возврата через билетную систему Интикетс истекли. Для получения более подробной информации о&nbsp;возврате или других возможных решениях, просим вас обращаться к&nbsp;организатору мероприятия. Его контакты можно найти на&nbsp;сайте, где были куплены билеты.',
            active: false
          },
          {
            title:
              'Что означает, когда система не производит возврат, а рекомендует обратиться к организатору?',
            text: 'Это означает, что возврат по&nbsp;вашему мероприятию производит организатор. Его контакты можно найти на&nbsp;сайте, где были куплены билеты.',
            active: false
          },
          {
            title:
              'Можно ли вернуть пригласительные или билеты, которые были получены в результате выигрыша?',
            text: 'Возврат выигранного или пригласительного билета не&nbsp;может быть осуществлен через билетную систему. Чтобы узнать о&nbsp;возможностях возврата, вам следует обратиться по&nbsp;месту получения билетов или к&nbsp;организатору мероприятия.',
            active: false
          },
          {
            title: 'Что делать, если я перепутал(а) дату и/или время при покупке билетов',
            text: 'В&nbsp;таком случае, рекомендуем связаться с&nbsp;организатором мероприятия, у&nbsp;которого были приобретены билеты, и&nbsp;запросить изменение даты или времени или возврат билетов. Обычно, на&nbsp;сайте, где вы&nbsp;купили билеты, можно найти контактные данные организатора. Обратитесь к&nbsp;ним как можно скорее, чтобы выяснить, доступны&nbsp;ли какие-либо варианты для изменения вашего билета. Организаторы обычно стараются помочь своим зрителям и&nbsp;могут предложить варианты, в&nbsp;зависимости от&nbsp;своей политики возвратов и&nbsp;обменов.',
            active: false
          },
          {
            title: ' Как отменить заявку, если я передумал возвращать билет?',
            text: `Все запросы на&nbsp;возврат рассматриваются и&nbsp;обрабатываются в&nbsp;течение дня. Наша команда приложит все усилия, чтобы отменить ваш запрос на&nbsp;возврат, однако не&nbsp;может гарантировать успешное выполнение этой операции. На&nbsp;момент вашего обращения запрос на&nbsp;возврат может быть уже обработан. Чтобы уточнить возможность отмены, пожалуйста, свяжитесь с&nbsp;нами через форму <a href="${ExternalLink.feedback}">обратной связи</a>`,
            active: false
          }
        ]
      },
      {
        title: 'Возврат билетов по отмене/замене/переносу мероприятия',
        active: false,
        isBlock: true,
        questions: [
          {
            title:
              'Как понять, что с моим мероприятием? Оно отменено, перенесено или пройдет, как запланировано?',
            text: 'Организатор сам принимает решение о&nbsp;судьбе каждого своего мероприятия в&nbsp;каждом из&nbsp;городов, на&nbsp;основании местных постановлений и&nbsp;указов глав городов или областей. Информацию о&nbsp;новом статусе мероприятия организатор размещает на&nbsp;своем сайте в&nbsp;сети Интернет.',
            active: false
          },
          {
            title: ' Если мое мероприятие отменено, что мне делать?',
            text: 'Ожидайте информации о&nbsp;начале возврата на&nbsp;своей электронной почте. Как только организатор передаст нам информацию об&nbsp;изменении в&nbsp;мероприятии и&nbsp;откроет возврат, мы&nbsp;сразу отправим вам письмо&nbsp;&mdash; уведомление.',
            active: false
          },
          {
            title:
              'Если мое мероприятие перенесено организатором на новую дату или заменено, что мне делать?',
            text: 'В&nbsp;этом случае Интикетс или сам организатор уведомят вас письмом на&nbsp;электронную почту о&nbsp;переносе или замене мероприятия. В&nbsp;этом письме будет информация о&nbsp;новой дате, времени или названии мероприятия. Также будет предоставлена инструкция о&nbsp;том, когда и&nbsp;где будет открыт возврат для тех клиентов, кто решит вернуть билеты.',
            active: false
          },
          {
            title: ' Почему я не получил письмо – уведомление?',
            text: `Возможные причины:
            <ul>
            <li>Письмо было отправлено на&nbsp;неправильную электронную почту. Проверьте, что вы&nbsp;указали правильный адрес электронной почты при покупке через форму восстановления</li>
            <li>Письмо попало в&nbsp;папку &laquo;Спам&raquo;, &laquo;Рассылки&raquo; или &laquo;Нежелательная почта&raquo;</li>
            <li>Почтовый ящик переполнен</li>
            <li>Почтовый сервер получателя заблокировал электронное письмо</li>
</ul>`,
            active: false
          },
          {
            title: 'Как понять, прошла заявка на возврат или нет?',
            text: 'При создании запроса на&nbsp;возврат, система выдает уведомление о&nbsp;том, что запрос успешно осуществлён. Чтобы проверить статус запроса, можно повторить процесс возврата и&nbsp;посмотреть сведения о&nbsp;дате и&nbsp;времени обращения. Если информация отсутствует, запрос не&nbsp;был создан.',
            active: false
          },
          {
            title: 'Если я верну билеты, то какую сумму я получу?',
            text: 'В&nbsp;случае, если мероприятие отменено, перенесено или заменено и&nbsp;возврат открыт, вы&nbsp;сможете вернуть сумму, установленную организатором (как правило, это полная сумма приобретенных билетов).<br/><br/>Сервисный сбор возврату не&nbsp;подлежит (если сервисный сбор взимался при приобретении билетов Покупателем)',
            active: false
          },
          {
            title: 'Когда вернутся деньги за возвращенные билеты?',
            text: 'Как правило, возврат средств занимает от&nbsp;одного до&nbsp;трёх дней. В&nbsp;редких случаях возврат может занимать до&nbsp;30&nbsp;дней (зависит от&nbsp;Банка, выпустившего вашу карту). Возврат денежных средств за&nbsp;билеты на&nbsp;мероприятия периода повышенной готовности в&nbsp;настоящий момент осуществляется согласно <a href="http://static.government.ru/media/files/Xj5Hb4wKjWkrf42pdnX7GhUgPwPWbh4x.pdf">постановлению Правительства Российской Федерации от 6 июня 2020 г. № 830.</a>',
            active: false
          },
          {
            title: 'Почему сумма к возврату меньше, чем была сумма к оплате?',
            text: 'Возврат осуществляется в соответствии с <a href="https://publication.pravo.gov.ru/Document/View/0001201907180040">Федеральным законом от 18.07.2019 N 193-ФЗ "О внесении изменений в Закон Российской Федерации "Основы законодательства Российской Федерации о культуре", действующего с 01.09.2019.</a><br/><br/>Дополнительно ООО &laquo;Интикетс&raquo; удерживает с&nbsp;Покупателя 10&nbsp;% (Десять процентов) от&nbsp;номинальной стоимости каждого возвращенного электронного билета в&nbsp;заказе, в&nbsp;качестве платы за&nbsp;обработку возврата.<br/><br/>Сервисный сбор возврату не&nbsp;подлежит (если сервисный сбор взимался при приобретении Покупателем билетов).',
            active: false
          }
        ]
      },
      {
        title: 'У меня поменялись планы, хочу вернуть билет',
        active: false,
        isBlock: true,
        questions: [
          {
            title: ' Какие правила действуют при возврате по собственному желанию?',
            active: false,
            text: `При возврате билетов мы руководствуемся Федеральным законом от 18.07.2019 N 193-ФЗ <a href="https://www.consultant.ru/cons/cgi/online.cgi?req=doc&base=LAW&n=329296&fld=134&dst=100008,0&rnd=0.011703243628272242#04308796044352099">"О внесении изменений в Закон Российской Федерации "Основы законодательства Российской Федерации о культуре"</a><br/><br/>Сумма возврата будет зависеть от того, за сколько дней была подана заявка:
<ul>
<li>
не&nbsp;позднее, чем за&nbsp;10 (десять) дней до&nbsp;мероприятия&nbsp;&mdash; 100% цены билета*
</li>
<li>
менее, чем за&nbsp;10 (десять) дней, но&nbsp;не&nbsp;позднее чем за&nbsp;5 (пять) дней до&nbsp;мероприятия&nbsp;&mdash; 50% цены билета*
</li>
<li>
менее, чем за&nbsp;5 (пять) дней, но&nbsp;не&nbsp;позднее чем за&nbsp;3 (три) дня до&nbsp;мероприятия&nbsp;&mdash; 30% цены билета*
</li>
<li>
менее, чем за&nbsp;3 (три) дня до&nbsp;мероприятия стоимость билета&nbsp;НЕ возвращается
</li>
<li>
 подробности можно прочитать по <a href="${ExternalLink.oferta}">ССЫЛКЕ</a>
</li>
</ul><br/>* Дополнительно:<br/><br/>ООО &laquo;Интикетс&raquo; удерживает 10&nbsp;% от&nbsp;стоимости каждого возвращенного билета в&nbsp;заказе, в&nbsp;качестве платы за&nbsp;обработку возврата.<br/><br/>Сервисный сбор возврату не подлежит (если он взимался при приобретении билетов)`
          },
          {
            title: 'Если концерт, сегодня я могу сделать возврат?',
            active: false,
            text: 'Согласно <a href="https://www.consultant.ru/cons/cgi/online.cgi?req=doc&base=LAW&n=329296&fld=134&dst=100008,0&rnd=0.011703243628272242#04308796044352099">правилам возврата</a>, менее, чем за 3 (три) дня до дня проведения мероприятия стоимость билета не возвращается.'
          },
          {
            title: 'Как оформить возврат по причине болезни?',
            active: false,
            text: 'По&nbsp;вопросу возврата билета по&nbsp;причине болезни вам необходимо обратиться к&nbsp;организатору мероприятия с&nbsp;подтверждающими документами. Контакты организатора вы&nbsp;можете найти на&nbsp;сайте, где производилась покупка билетов.'
          }
        ]
      }
    ]
  },
  {
    id: 1,
    title: 'как восстановить билет?',
    questions: [
      {
        title: 'Я потерял билеты. Как мне их восстановить?',
        text: `Если у&nbsp;вас возникли трудности с&nbsp;билетами, не&nbsp;переживайте, их&nbsp;можно восстановить. Для этого нужно воспользоваться специальным сервисом, который поможет отправить новые билеты на&nbsp;ваш электронный адрес. Чтобы это сделать, следуйте инструкции на&nbsp;экране.<br/><br/><a href="${ExternalLink.resend}">Начать процедуру восстановления билета</a>`,
        active: false
      },
      {
        title: 'Мне не пришли электронные билеты на почту, что делать?',
        active: false,
        text: `Если вы&nbsp;не&nbsp;получили билеты или не&nbsp;можете их&nbsp;найти, проверьте в&nbsp;своей электронной почте папки: &laquo;Спам&raquo;, &laquo;Рассылки&raquo;, &laquo;Нежелательная почта&raquo;.<br/><br/>Если письмо найти не удалось, то воспользуйтесь нашим <a href="${ExternalLink.resend}">Сервисом автоматического восстановления билетов.</a>`
      },
      {
        title: 'В заказе было несколько билетов, а на почту пришел только один билет.',
        active: false,
        text: `Если вы&nbsp;приобрели несколько билетов в&nbsp;рамках одного заказа, то&nbsp;они отправляются на&nbsp;вашу электронную почту в&nbsp;виде PDF-файла единым файлом. Если пришел только один билет, мы&nbsp;рекомендуем проверить вложения к&nbsp;письму&nbsp;&mdash; остальные билеты должны находиться на&nbsp;следующих страницах этого файла. В&nbsp;случае, если билет все&nbsp;же будет один, напишите нам в&nbsp;<a href="${ExternalLink.feedback}">форму обратной связи.</a>`
      }
    ]
  },
  {
    id: 2,
    title: 'как поменять билет?',
    questions: [
      {
        title: 'Можно ли поменять электронные билеты на другие электронные билеты с доплатой?',
        text: 'Мы&nbsp;не&nbsp;осуществляем обмен билетов. Пожалуйста, обратитесь к&nbsp;организатору вашего мероприятия. Он&nbsp;указан на&nbsp;ваших билетах.',
        active: false
      },
      {
        title: 'Можно ли поменять фамилию и имя на электронном билете?',
        text: 'Мы&nbsp;не&nbsp;меняем фамилию и&nbsp;имя на&nbsp;билетах, так как они привязаны к&nbsp;заказу.',
        active: false
      },
      {
        title: 'В электронном билете указано мое имя, может ли по нему пройти мой знакомый?',
        text: 'Билет на&nbsp;мероприятие не&nbsp;является именным и&nbsp;может быть использован любым человеком, за&nbsp;исключением случаев, когда оплата была произведена с&nbsp;использованием Пушкинской карты, или билет был куплен по&nbsp;льготному тарифу.',
        active: false
      }
    ]
  },
  {
    id: 3,
    title: 'нужно ли распечатывать электронные билеты?',
    description: {
      title: 'Нужно ли распечатывать электронные билеты?',
      text: 'Мы&nbsp;рекомендуем распечатать электронные билеты. Это упрощает процесс сканирования штрихкодов и&nbsp;ускоряет проход зрителей в&nbsp;зал. Если у&nbsp;вас нет возможности напечатать билет, заранее откройте его на&nbsp;электронном устройстве, увеличьте штрихкод и&nbsp;яркость экрана.<br/><br/>Обратите внимание, что не&nbsp;все площадки могут принимать электронные билеты на&nbsp;вашем смартфоне. Перед посещением мероприятия уточните у&nbsp;организатора, нужно&nbsp;ли печатать билет или достаточно показать его в&nbsp;электронном виде.'
    },
    questions: []
  },
  {
    id: 4,
    title: 'как купить билет?',
    description: {
      title: 'Как купить билет?',
      text: `Приобрести билеты на&nbsp;мероприятия, воспользовавшись сервисом Intickets, вы&nbsp;можете на&nbsp;сайтах наших клиентов. Некоторые из&nbsp;них представлены на&nbsp;нашем сайте в&nbsp;разделе <a href="${ExternalLink.clients}">клиенты.</a>`
    },
    questions: [
      {
        title: 'Что делать, если возникла ошибка при оплате?',
        active: false,
        text: `<h5>Оплата банковской картой</h5> Оплата может не пройти, если:
<ol>
<li>Вы ввели неверные данные карты</li>
<li>У карты истёк срок действия</li>
<li>На карте недостаточно средств</li>
<li>Банк, который выпустил карту, не поддерживает технологию 3-D Secure</li>
<li>Банк установил запрет на оплату в интернете</li>
</ol><br/>Если возникли проблемы с&nbsp;получением кода 3-D Secure или проводится длительная проверка безопасности оплаты, обратитесь в&nbsp;банк, выпустивший вашу карту.<br/><br/>
Что делать, если оплата не прошла:
<ol>
<li>Повторите попытку через 15-30 минут</li>
<li>Обратитесь в банк, выпустивший карту</li>
<li>Попробуйте оплатить другой картой или выберете альтернативный способ оплаты</li>
<li>Попробуйте оплатить заказ с другого устройства</li>
</ol><br/>
Если это не поможет, напишите нам на <a href="${ExternalLink.feedback}">форму обратной связи</a>
<h5>Оплата через СБП</h5>
Оплата может не пройти, если:<br/>
<ol>
<li> Банк-получатель не подключен к СБП</li>
<li>Не выполнены требования безопасности</li>
<li> Недостаточно средств на счете</li>
</ol><br/>
Если оплата не прошла, обратитесь в банк, выпустивший карту<br/><br/>
Если на&nbsp;странице произошёл сбой загрузки и&nbsp;вы&nbsp;не&nbsp;получили билет после оплаты, то&nbsp;необходимо подождать 30-60 минут для подтверждения платежа. Если билет не&nbsp;пришёл в&nbsp;течение указанного времени, напишите нам в&nbsp;<a href="${ExternalLink.feedback}">форму обратной связи</a>
`
      },
      {
        title: ' Что делать, если деньги с карты списаны, а билет не пришел?',
        active: false,
        text: `Не паниковать! К сожалению, такое хоть и очень редко, но бывает.<br>Ваши деньги не исчезли. Мы обязательно поможем.
<ol>
<li>Проверьте электронную почту еще раз. Возможно, билет попал в&nbsp;папку &laquo;Спам&raquo;, &laquo;Рассылки&raquo; или &laquo;Нежелательная почта&raquo;</li>
<li>Проверьте выписку по счету в своем банке, чтобы убедиться, что деньги были списаны</li>
<li>Если статус оплаты &laquo;в&nbsp;обработке&raquo;, обратитесь в&nbsp;службу поддержки своего банка</li>
<li>Eсли статус оплаты "выполнено", воспользуйтесь <a href="${ExternalLink.resend}">восстановлением билетов</a> для повторной отправки билета</li>
</ol><br/>Если вы не смогли восстановить билеты, напишите нам на <a href="${ExternalLink.feedback}">форму обратной связи</a>, и обязательно приложите чек об оплате. Эта поможет нам быстрее решить ваш вопрос.`
      }
    ]
  },
  {
    id: 5,
    title: 'пушкинская карта',
    description: {
      title: 'пушкинская карта',
      text: '&laquo;Пушкинская карта&raquo;&nbsp;&mdash; это совместный проект Минкультуры, Министерства цифрового развития и&nbsp;&laquo;Почта Банка&raquo;, который позволяет молодым людям посещать мероприятия за&nbsp;счёт федерального бюджета. Подробности на&nbsp;<a href="https://xn--80atoqz.xn--p1ai/">сайте проекта.</a>'
    },
    questions: [
      {
        title: 'Сколько билетов можно приобрести по Пушкинской карте?',
        text: 'На&nbsp;одно мероприятие, одно время и&nbsp;дату владелец Пушкинской карты может приобрести только один билет. Сумма заказа не&nbsp;должна превышать 5000&nbsp;рублей.',
        active: false
      },
      {
        title: 'Как купить билет по Пушкинской карте?',
        active: false,
        text: 'Выберите мероприятие с&nbsp;особой отметкой &laquo;Пушкинская карта&raquo;, определитесь с&nbsp;датой и&nbsp;местом в&nbsp;зрительном зале. Если мероприятие участвует в&nbsp;&laquo;Пушкинской программе&raquo;, то&nbsp;при выборе способа оплаты система предложит: &laquo;Оплатить Пушкинской картой&raquo;.'
      },
      {
        title: 'Можно по Пушкинской карте покупать билеты другим людям?',
        active: false,
        text: 'Нет. По&nbsp;Пушкинской карте можно покупать билеты только для себя. Нельзя покупать билеты для других людей, даже для родственников и&nbsp;друзей.'
      },
      {
        title: 'Почему я не могу оплатить билет по Пушкинской карте?',
        active: false,
        text: 'Оплата по&nbsp;Пушкинской карте доступна только тем организатором, которые являются участниками государственной программы &laquo;Пушкинская карта&raquo;'
      },
      {
        title: 'Я могу вернуть билет, купленный по Пушкинской карте?',
        active: false,
        text: 'Приобретенные билеты имеют установленные сроки возврата. Вы&nbsp;можете вернуть электронные билеты в&nbsp;соответствии с&nbsp;Правилами возврата.'
      },
      {
        title: 'Что делать если ошибся в ФИО?',
        active: false,
        text: `Составить обращение в <a href="${ExternalLink.feedback}">форме обратной связи</a> и приложить следующие документы:<br/>
<ul>
<li>Чек оплаты</li>
<li>Выписку по операциям из ПБО (своего личного кабинета Почта Банка) как держателя карты</li>
</ul>`
      },
      {
        title: 'Кто-то совершил покупку по моей Пушкинской карте, что делать?',
        active: false,
        text: 'При обнаружении неавторизованных трат с&nbsp;вашей Пушкинской карты, вам необходимо сразу обратиться в&nbsp;службу поддержки Почта Банка и&nbsp;написать заявление. Банк проведёт расследование и&nbsp;предоставит дальнейшие инструкции, связанные с&nbsp;оспариванием этой транзакции и&nbsp;блокировкой вашей карты, если это необходимо.'
      }
    ]
  },
  {
    id: 6,
    title: 'подробнее о билетах',
    questions: [
      {
        title: 'Мне не пришли электронные билеты на почту, что делать?',
        active: false,
        text: `Если вы&nbsp;не&nbsp;получили билеты или не&nbsp;можете их&nbsp;найти, проверьте в&nbsp;своей электронной почте папки: &laquo;Спам&raquo;, &laquo;Рассылки&raquo;, &laquo;Нежелательная почта&raquo;.<br/><br/>Если письмо найти не удалось, то воспользуйтесь нашим <a href="${ExternalLink.resend}">Сервисом автоматического восстановления билетов.</a>`
      },
      {
        title: ' В заказе было несколько билетов, а на почту пришел только один билет.',
        active: false,
        text: `Если вы&nbsp;приобрели несколько билетов в&nbsp;рамках одного заказа, то&nbsp;они отправляются на&nbsp;вашу электронную почту в&nbsp;виде PDF-файла единым файлом. Если пришел только один билет, мы&nbsp;рекомендуем проверить вложения к&nbsp;письму&nbsp;&mdash; остальные билеты должны находиться на&nbsp;следующих страницах этого файла. В&nbsp;случае, если билет все&nbsp;же будет один, напишите нам в&nbsp;<a href="${ExternalLink.feedback}">форму обратной связи.</a>`
      },
      {
        title: 'Можно ли поменять электронные билеты на другие электронные билеты с доплатой?',
        active: false,
        text: 'Мы&nbsp;не&nbsp;осуществляем обмен билетов. Пожалуйста, обратитесь к&nbsp;организатору вашего мероприятия. Он&nbsp;указан на&nbsp;ваших билетах.'
      },
      {
        title: 'Можно ли поменять фамилию и имя на электронном билете?',
        active: false,
        text: 'Мы&nbsp;не&nbsp;меняем фамилию и&nbsp;имя на&nbsp;билетах, так как они привязаны к&nbsp;заказу.'
      },
      {
        title: 'В электронном билете указано мое имя, может ли по нему пройти мой знакомый?',
        active: false,
        text: 'Билет на&nbsp;мероприятие не&nbsp;является именным и&nbsp;может быть использован любым человеком, за&nbsp;исключением случаев, когда оплата была произведена с&nbsp;использованием Пушкинской карты, или билет был куплен по&nbsp;льготному тарифу.'
      }
    ]
  },
  {
    id: 7,
    title: 'какое возрастное ограничение?',
    description: {
      title: 'Какое возрастное ограничение?',
      text: 'Возрастное ограничение для мероприятия обычно указывается на&nbsp;сайте организатора мероприятия или на&nbsp;самом билете. Если вы&nbsp;хотите узнать, подходите&nbsp;ли вы&nbsp;по&nbsp;возрастным ограничениям, рекомендуется обратиться к&nbsp;организатору мероприятия или проверить информацию на&nbsp;их&nbsp;веб-сайте. Организаторы определяют возрастные ограничения в&nbsp;соответствии с&nbsp;правилами и&nbsp;требованиями, но&nbsp;иногда могут быть исключения или дополнительные условия.'
    },
    questions: []
  }
])

const showAllSections = ref(false)
const activeSection = ref<FAQ['id'] | undefined>(isLgAndUp.value ? 0 : undefined)
const showAll = ref(false)
const sections = computed(() =>
  questions
    .map(({ id, title }) => ({ id, title }))
    .filter((_, index) => (showAllSections.value ? true : index < 3))
)

const activeSectionQuestions = computed(() =>
  questions.find((item) => item.id === activeSection.value)
)
</script>
